import Link from 'next/link'
import React from 'react'
import EnpalLogo from 'assets/icons/enpal-logo.svg'
import cn from 'classnames'
import LANGUAGE_KEYS from 'utils/languageKeys'

type Props = {
  lightMode?: boolean
  login?: boolean
  preview?: boolean
  dictionary: { [key: string]: string }
  isAppUser: boolean | null
}

export default function Footer({
  lightMode,
  login,
  preview,
  dictionary,
  isAppUser,
}: Props): JSX.Element {
  return (
    <div
      key="footer"
      id="footer"
      className="flex w-full text-downriver60 md:text-sm footer-sm:text-xs footer-xs:text-[8px] bg-transparent mt-[100px] z-0"
    >
      <footer
        data-testid="footer"
        className={`h-full top-0 static w-full px-6 py-6 mb-0 transition duration-500 border-0 ${
          lightMode
            ? 'lg:bg-gradient-to-b from-footer-gradient-top to-footer-gradient-bottom'
            : undefined
        }`}
      >
        {isAppUser ? (
          <div className="h-4" />
        ) : (
          <ul
            className={cn(
              'flex h-full',
              'justify-center items-center relative'
            )}
          >
            {!login && (
              <li
                className={cn(
                  'mt-0 mb-0 lg:dark:text-white',
                  { 'md:mr-10 footer-sm:mr-5 footer-xs:mr-2': !preview },
                  { 'text-white filter drop-shadow-enpal-footer': lightMode },
                  { 'text-downriver60': !lightMode }
                )}
              >
                {dictionary
                  ? dictionary[LANGUAGE_KEYS.FOOTER_COPY_RIGHT]
                  : `© ${new Date().getFullYear()} Enpal`}
              </li>
            )}
            {login && (
              <li
                className={cn(
                  'mt-0 dark:text-white',
                  { 'md:mr-10 footer-sm:mr-5 footer-xs:mr-2': !preview },
                  { 'text-white filter drop-shadow-enpal-footer': lightMode },
                  { 'text-downriver60': !lightMode }
                )}
              >
                <Link
                  className="hidden lg:block absolute left-0 "
                  href={dictionary[LANGUAGE_KEYS.FOOTER_LOGO]}
                >
                  <EnpalLogo className="w-[70px] h-[20px]" />
                </Link>
              </li>
            )}
            <li
              className={cn(
                'mt-0 dark:text-white',
                'md:mr-10 footer-sm:mr-5 footer-xs:mr-2 mt-0 dark:text-white',
                { 'text-white filter drop-shadow-enpal-footer': lightMode },
                { 'text-downriver60': !lightMode }
              )}
            >
              <Link
                href={dictionary[LANGUAGE_KEYS.FOOTER_IMPRESSUM]}
                className=" hover:underline"
              >
                {dictionary
                  ? dictionary[LANGUAGE_KEYS.FOOTER_IMPRINT]
                  : 'Impresum'}
              </Link>
            </li>
            <li
              className={cn(
                'mt-0 dark:text-white',
                { 'md:mr-10 footer-sm:mr-5 footer-xs:mr-2': !preview },
                { 'text-white filter drop-shadow-enpal-footer': lightMode },
                { 'text-downriver60': !lightMode }
              )}
            >
              <Link
                href={dictionary[LANGUAGE_KEYS.FOOTER_DATENSCHUTZ]}
                className=" hover:underline"
              >
                {dictionary
                  ? dictionary[LANGUAGE_KEYS.FOOTER_PRIVACY]
                  : 'Datenschutz'}
              </Link>
            </li>
          </ul>
        )}
      </footer>
    </div>
  )
}
